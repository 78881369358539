"use client";

import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const toastOptions = {
  unstyled: true,
};

const Toaster = ({ ...props }: ToasterProps) => (
  <Sonner
    className="
    toaster group fixed z-[9999] flex w-[calc(100%-2rem)] justify-center text-background
    "
    position="top-center"
    offset={8}
    toastOptions={toastOptions}
    {...props}
  />
);

export { Toaster };
